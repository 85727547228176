<template>
  <div>
    <h1>Document Type Details</h1>
    <div class="document-type-container" v-if="documentType">
      <h3>Document Type Information</h3>
      <div class="document-type-details">
        <p><strong>Name:</strong> {{ documentType.name || 'Unknown' }}</p>
        <p><strong>Is a Letterhead:</strong> {{ documentType.isALetterhead ? 'Yes' : 'No' }}</p>
        <p><strong>QR Code Placement X:</strong> {{ documentType.qrCodePlacementX }}</p>
        <p><strong>QR Code Placement Y:</strong> {{ documentType.qrCodePlacementY }}</p>
        <p><strong>QR Code Placement Page:</strong> {{ documentType.qrCodePlacementPage }}</p>
        <p><strong>Version:</strong> {{ documentType.version }}</p>
        <p><strong>Created Date:</strong> {{ formatDate(documentType.createdDate) || 'Unknown' }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { EModificationType } from '../globals';
import Vue from 'vue';

interface DocumentType {
  isALetterhead: boolean;
  name: string;
  qrCodePlacementX: number;
  qrCodePlacementPage: number;
  qrCodePlacementY: number;
  version: number;
  createdDate: string;
  id: string;
}

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documentType: null as DocumentType | null, // Holds document type data
      isLoading: true, // Loading state for API call
    };
  },
  mounted() {
    this.fetchDocumentType(); // Fetch document type on mount
  },
  methods: {
    // Fetch the document type by id
    fetchDocumentType: async function () {
      try {
        // Call the store action to fetch document type by ID
        const documentType = await this.$store.dispatch(EModificationType.FETCH_DOCUMENT_TYPE_BY_ID, { id: this.id });

        // Set the fetched data to the documentType variable
        this.documentType = documentType;
      } catch (error) {
        console.error('Error fetching document type:', error);
        alert('Failed to fetch document type. Please try again.');
      } finally {
        this.isLoading = false; // Ensure loading ends
      }
    },
    // Format date method
    formatDate: function (dateStr: string): string {
      const date = new Date(dateStr);
      return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB');
    },
  },
});
</script>

<style scoped>
.document-type-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.document-type-details {
  padding: 15px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.document-type-details p {
  margin: 10px 0;
  font-size: 16px;
}

p strong {
  font-weight: bold;
}
</style>
