    // Start of Selection
    <template>
      <div class="login-page">
        <div class="login-left">
          <div class="image-container">
            <div class="overlay-text">
              Simple & Secure Document Verification For Your Business or Organization
            </div>
          </div>
        </div>
        <div class="login-right">
          <div class="login-container">
            <router-link to="/login">
              <img src="/logo.png" alt="credit-mate" class="logo">
            </router-link>
            <h2></h2>
            <form @submit.prevent="onLogin">
              <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" id="email" v-model="email" required placeholder="Enter your email">
              </div>
              <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" id="password" v-model="password" required placeholder="Enter your password">
              </div>
              <div class="form-actions">
                <button type="submit" class="login-button" :disabled="isLoggingIn">{{ isLoggingIn ? 'Signing In' : 'Sign In' }}</button>
                <router-link to="/forgot-password" class="forgot-password">Forgot Password?</router-link>
              </div>
            </form>
            <p v-if="error" class="error-message">{{ error }}</p>
          </div>
          <footer class="footer">
            <p>{{ footerText }}</p>
          </footer>
        </div>
      </div>
    </template>
    
    <script>
    import { EModificationType, EGettersType, DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS } from '../globals';
    import Vue from 'vue';
    import { mapGetters } from 'vuex';
    import { getUserRole } from '../util';
    
    export default Vue.extend({
      data() {
        return {
          email: "",
          password: "",
          error: "",
          isLoggingIn: false
        };
      },
      computed: {
        ...mapGetters([
          EGettersType.GET_IS_AUTHENTICATED
        ]),
        footerText() {
          return `© ${new Date().getFullYear()} Creditmate Proprietary Limited (BW00005944708). All rights reserved.`;
        }
      },
      methods: {
        onLogin: function () {
          this.isLoggingIn = true;
          this.$store.dispatch(EModificationType.LOG_IN_WITH_LOCAL_AUTH, {
            email: this.email,
            password: this.password
          })
          .then(() => {
            if (this.isAuthenticated) {
              return this.$store.dispatch(EModificationType.FETCH_OWN_ACCOUNT);
            } else {
              return;
            }
          })
          .then(() => {
            console.log("isAuthenticated: ", this.isAuthenticated, getUserRole());
            if (this.isAuthenticated && getUserRole() !== '') {
              this.$router.push('/documents');
            }
          })
          .catch((error) => {
            this.error = error instanceof Error ? error.message : DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS;
          })
          .finally(() => {
            this.isLoggingIn = false;
          });
        }
      }
    });
    </script>
    
    <style scoped>
    .login-page {
      display: flex;
      height: 100vh;
    }
    
    .login-left {
      flex: 1;
      background: url('/src/assets/terrace.jpg') no-repeat center center;
      background-size: cover;
      position: relative; /* Needed for absolute positioning of the text */
    }
    
    .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white; /* Text color for contrast */
      font-size: 3.5em; /* Adjust the font size as needed */
      font-weight: bold; /* Make the text stand out */
      text-align: center; /* Center the text */
      width: 90%;
    }
    
    .login-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f7f6f6;
      position: relative;
    }
    
    .login-container {
      width: 400px;
      margin: 100px 0px 0px 0px;
      padding: 20px;
      background-color: #FFFFFF;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
      margin-bottom: auto;
    }
    
    .logo {
      width: 100px;
      margin-bottom: 20px;
    }
    
    h2 {
      color: #333;
      margin-bottom: 30px;
    }
    
    .form-group {
      margin-bottom: 20px;
      text-align: left;
    }
    
    label {
      display: block;
      margin-bottom: 8px;
      color: #666;
    }
    
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    
    .form-actions {
      margin-top: 20px;
      text-align: center;
    }
    
    .login-button {
      width: 100%;
      padding: 10px 20px;
      background-color: #007bff;
      font: 1em sans-serif;
      font-family: 'Oswald', sans-serif;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    .login-button:hover {
      background-color: #0056b3;
    }
    
    .login-button:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
    
    .forgot-password {
      display: block;
      margin-top: 12px;
      color: #007bff;
      text-decoration: none;
    }
    
    .forgot-password:hover {
      text-decoration: underline;
    }
    
    .error-message {
      color: red;
      text-align: center;
      margin-top: 12px;
    }
    
    .footer {
      width: 100%;
      text-align: center;
      padding: 20px 0;
      background-color: #f8f8f8;
      margin-top: auto;
      font-size: 14px;
      color: #575656;
      position: relative;
    }
    </style>
