<template>
  <div>
    <h1>Add New Direct Sales Agent</h1>
    <form @submit.prevent="handleSubmit" class="user-form">
      <div class="form-group">
        <label for="firstName">First Name:</label>
        <input type="text" id="firstName" v-model="firstName" required />
      </div>
      <div class="form-group">
        <label for="lastName">Last Name:</label>
        <input type="text" id="lastName" v-model="lastName" required />
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="phone">Phone:</label>
        <input type="tel" id="phone" v-model="phone" required />
      </div>
      <div class="form-group">
        <label for="photoFile">Upload Selfie Photo:</label>
        <input 
          type="file" 
          id="photoFile" 
          accept="image/*" 
          @change="handleFileChange" 
          required 
        />
      </div>
      <button type="submit" class="submit-button" :disabled="!isFormValid">Add Direct Sales Agent</button>
    </form>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EGettersType, EModificationType } from '../globals';
import Vue from 'vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default Vue.extend({
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      photoFile: null as File | null,
    };
  },
  computed: {
    ...mapGetters([EGettersType.GET_USER]),
    isFormValid(): boolean {
      return this.firstName.length > 0 && 
             this.lastName.length > 0 && 
             this.email.length > 0 && 
             this.phone.length > 0 && 
             this.photoFile !== null;
    },
  },
  methods: {
    formatPhoneNumber(phone: string) {
      const phoneNumber = parsePhoneNumberFromString(phone, 'BW');
      return phoneNumber ? phoneNumber.formatInternational() : phone;
    },
    handleFileChange(event: Event) {
      const files = (event.target as HTMLInputElement).files;
      if (files && files[0].type.startsWith('image/')) {
        this.photoFile = files[0];
      } else {
        this.photoFile = null;
      }
    },
    uploadFileToFirebase: async function (file: File): Promise<string> {
      try {
        const storageRef = ref(storage, `photos/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(snapshot.ref);
        return url;
      } catch (error) {
        console.log('File upload error:', error);
        throw new Error('Failed to upload file to Firebase.');
      }
    },
    handleSubmit: async function () {
      try {
        if (!this.photoFile) {
          throw new Error("Please upload a valid photo.");
        }

        const formattedPhone = this.formatPhoneNumber(this.phone);
        const photoUrl = await this.uploadFileToFirebase(this.photoFile);

        await this.$store.dispatch(EModificationType.CREATE_DIRECT_SALES_AGENT, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: formattedPhone,
          locale: this.user.locale,
          tz: this.user.tz,
          photoUrl: photoUrl,
          country: this.user.country
        });
        this.$router.push({ name: 'directSalesAgents' });
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e.message);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
  },
});
</script>

<style scoped>
.user-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #1db674;
}
</style>