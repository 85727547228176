export const NETWORK_ERROR_MESSAGE = "Network request failed";
export const UNEXPECTED_ERROR_PREFIX = "Unexpected token";
export const SERVER_UNREACHABLE = "The server could not be reached at this time. Please try again";
export const DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS = "Something went wrong. Our engineers are looking into the problem, and we apologise for any inconvenience caused";
export const DEFAULT_LIST_LIMIT = 20;

const baseURL = "https://serv.creditmate.me";

export const SERVICE_URLS: {[key: string]: string} = {
  creditmateAuthURI: process.env.creditMate_AUTH_URI || `${baseURL}/auth`,
  creditmateOpenURI: process.env.creditMate_OPEN_URI || `${baseURL}/open`,
  creditmateAppURI: process.env.creditMate_APP_URI || `${baseURL}/app`,
}

export enum EModificationType {
  // SET_FCM_TOKEN = "SET_FCM_TOKEN",
  // GET_FCM_TOKEN = "GET_FCM_TOKEN",
  FETCH_DOCUMENT_BY_ID = "FETCH_DOCUMENT_BY_ID",
  CREATE_DOCUMENT_TYPE = "CREATE_DOCUMENT_TYPE",
  SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT = "SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT",
  FETCH_ORGANIZATION_DOCUMENT_TYPES = "FETCH_ORGANIZATION_DOCUMENT_TYPES",
  VERIFY_DOCUMENT = "VERIFY_DOCUMENT",
  FETCH_DIRECT_SALES_AGENTS = "FETCH_DIRECT_SALES_AGENTS",
  SET_DIRECT_SALES_AGENTS = "SET_DIRECT_SALES_AGENTS",
  CREATE_DIRECT_SALES_AGENT = "CREATE_DIRECT_SALES_AGENT",
  SET_USER = "SET_USER",
  SET_DOCUMENT_TYPES = "SET_DOCUMENT_TYPES",
  CREATE_DOCUMENT = "CREATE_DOCUMENT",
  SET_EMPLOYEES = "SET_EMPLOYEES",
  LOG_OUT = "LOG_OUT",
  FETCH_OWN_ACCOUNT = "FETCH_OWN_ACCOUNT",
  CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
  CREATE_ORGANIZATION_EMPLOYEE = "CREATE_ORGANIZATION_EMPLOYEE",
  FETCH_EMPLOYEES = "FETCH_EMPLOYEES",
  SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK",
  RESET_PASSWORD = "RESET_PASSWORD",
  LOG_IN_WITH_LOCAL_AUTH = "LOG_IN_WITH_LOCAL_AUTH",
  SET_IS_NETWORK_ERROR_OR_TIMEOUT = "SET_IS_NETWORK_ERROR_OR_TIMEOUT",
  SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED",
  FETCH_USER_BY_ID = "FETCH_USER_BY_ID",
  FETCH_DOCUMENT_TYPE_BY_ID = "FETCH_DOCUMENT_TYPE_BY_ID",
  FETCH_ORGANIZATION_DOCUMENTS = "FETCH_ORGANIZATION_DOCUMENTS",
  SET_ORGANIZATION_DOCUMENTS = "SET_ORGANIZATION_DOCUMENTS"
}

export enum EGettersType {
  GET_USER = "user",
  GET_IS_AUTHENTICATED = "isAuthenticated",
  GET_USER_ROLE = "userRole",
  GET_ORGANIZATION_DOCUMENTS = "documents",
  GET_DOCUMENT_TYPES = "documentTypes",
  GET_DIRECT_SALES_AGENTS = "directSalesAgents",
  GET_EMPLOYEES = "employees",
  // GET_FCM_TOKEN = "fcmToken"
}

export enum EUserType {
  CONSUMER = "CONSUMER",
  EMPLOYEE = "EMPLOYEE",
  ADMIN = "ADMIN",
  COOPERATIVE = "COOPERATIVE",
  ORGANIZATION = "ORGANIZATION",
  DIRECT_SALES_AGENT = "DIRECT_SALES_AGENT"
}

export enum ESortOrderType {
  ASC = "ASC",
  DESC = "DESC",
  asc = "asc",
  desc = "desc"
}

export enum EDocumentTypeSortType {
  DATE = "DATE"
}

export enum EUserSortType {
  DATE = "DATE"
}

export enum EDocumentSortType {
  DATE = "DATE"
}
