<template>
  <div>
    <h1>Verify Document</h1>
    <div class="document-verification-container">
      <h3>Upload a PDF</h3>

      <div class="upload-section">
        <input 
          type="file" 
          @change="onFileSelected" 
          accept="application/pdf" 
          ref="fileInput"
        />
        <button 
          :disabled="!selectedFile || isUploading" 
          @click="uploadDocument" 
          class="upload-button"
        >
          {{ isUploading ? "Verifying..." : "Verify Document" }}
        </button>
      </div>

      <div v-if="report" class="document-details-section">
        <h3>Verification Report</h3>
        <div v-if="report.document" class="document-details">
          <p><strong>Name:</strong> {{ report.document.name || 'Unknown' }}</p>
          <p><strong>Is a Letter:</strong> {{ report.document.isALetter ? 'Yes' : 'No' }}</p>
          <p><strong>Created Date:</strong> {{ formatDate(report.document.createdDate) || 'Unknown' }}</p>
          <p><strong>Verification Status:</strong> {{ report.isValid ? 'Valid' : 'Invalid' }}</p>
        </div>

        <div v-else>
          <p>No document details available for this report.</p>
        </div>

        <h3>PDF Viewer</h3>
        <div class="pdf-viewer">
          <iframe
            v-if="pdfUrl"
            :src="pdfUrl"
            width="100%"
            height="600px"
            frameborder="0"
          ></iframe>
          <p v-else class="unable-to-load-document">Unable to load document</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';

// Define the structure of the document report
interface Document {
  name: string;
  isALetter: boolean;
  documentTypeId: string;
  documentUrl: string;
  base64EncodedDoc: string;
  createdDate: string;
}

interface Report {
  document: Document;
  isValid: boolean;
}

export default Vue.extend({
  data() {
    return {
      selectedFile: null as File | null,
      report: null as Report | null, // To hold the verification report
      pdfUrl: '', // To display the PDF
      isUploading: false, // Track if the document is uploading
    };
  },
  methods: {
    // Handle file selection
    onFileSelected(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length > 0) {
        this.selectedFile = fileInput.files[0];

        // Clear previous report and PDF when a new file is selected
        this.report = null;
        this.pdfUrl = '';
      }
    },

    // Upload the document and verify it
    async uploadDocument() {
      if (!this.selectedFile) return;

      this.isUploading = true; // Set the uploading status to true

      try {
        // Create a FormData object
        const formData = new FormData();
        formData.append('document', this.selectedFile);

        // Dispatch the VERIFY_DOCUMENT action with the FormData object
        const report = await this.$store.dispatch(EModificationType.VERIFY_DOCUMENT, { formData });

        // Set the report data and PDF URL
        this.report = report;
        this.loadPdfFromBase64(report.document.base64EncodedDoc);

        // Clear the file input and selectedFile after success
        this.selectedFile = null;
        (this.$refs.fileInput as HTMLInputElement).value = ''; // Clear the input field

      } catch (e) {
        console.error('Error verifying document:', e);
      } finally {
        this.isUploading = false; // Reset the uploading status
      }
    },

    // Load PDF from base64 and create blob URL
    loadPdfFromBase64(base64: string) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      this.pdfUrl = URL.createObjectURL(blob);
    },

    // Format date
    formatDate(dateStr: string): string {
      const date = new Date(dateStr);
      return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB');
    },
  },
});
</script>

<style scoped>
.document-verification-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.upload-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.upload-button {
  padding: 10px 20px;
  margin-left: 40px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #2dce89;
  color: #fff;
}

.upload-button:hover {
  background-color: #1db674;
}

.document-details-section {
  margin-top: 20px;
}

.document-details {
  padding: 15px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.pdf-viewer {
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #ffffff;
}

.pdf-viewer iframe {
  width: 100%;
  height: 600px;
}

.unable-to-load-document {
  padding: 10px;
}
</style>
