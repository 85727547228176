<template>
  <div>
    <h1>Documents</h1>
    <DocumentList />
  </div>
</template>

<script>
import DocumentList from '../components/DocumentList.vue';

export default {
  name: 'DocumentsView',
  components: {
    DocumentList
  },
};
</script>