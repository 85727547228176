<template>
  <div class="document-view-container" v-if="document">
    <h1>Document Details</h1>
    <div class="document-details">
      <p><strong>Name:</strong> {{ document.name }}</p>
      <p><strong>Is a Letter:</strong> {{ document.isALetter ? 'Yes' : 'No' }}</p>
      <p><strong>Created By:</strong> {{ document.createdBy }}</p>
      <p><strong>Created Date:</strong> {{ createdDate }}</p>
    </div>

    <h3>PDF Viewer</h3>
    <div class="pdf-viewer">
      <iframe
        v-if="pdfUrl"
        :src="pdfUrl"
        width="100%"
        height="600px"
        frameborder="0"
      ></iframe>
      <p v-else class="unable-to-load-document">Unable to load document</p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';

// Define the structure of the document object
interface Document {
  _id: string;
  name: string;
  isALetter: boolean;
  documentTypeId: string;
  createdBy: string;
  documentUrl?: string; // This may or may not exist
  base64EncodedDoc?: string; // This may or may not exist
  createdDate: string;
}

export default Vue.extend({
  async created() {
    try {
      await this.fetchDocument();
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e.message);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  data() {
    return {
      document: null as Document | null, // Use the Document interface
      pdfUrl: '',
    };
  },
  computed: {
    createdDate(): string {
      if (!this.document) {
        return '';
      }
      return new Date(this.document.createdDate).toLocaleDateString('en-GB');
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    async fetchDocument() {
      try {
        const fetchedDocument = await this.$store.dispatch(EModificationType.FETCH_DOCUMENT_BY_ID, { documentId: this.id });
        this.document = fetchedDocument;

        if (this.document && this.document.base64EncodedDoc) { // Add null check for document
          // Convert base64 PDF to a URL that can be rendered in an iframe
          const byteCharacters = atob(this.document.base64EncodedDoc);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          this.pdfUrl = URL.createObjectURL(blob);
        } else if (this.document && this.document.documentUrl) { // Add null check for document
          // Use the provided document URL if available
          this.pdfUrl = this.document.documentUrl;
        }
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e.message);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  },
});
</script>

<style scoped>
.document-view-container {
  margin: 0 auto;
  padding: 20px;
}

.document-details {
  padding: 10px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.pdf-viewer {
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #ffffff;
}

.unable-to-load-document {
  padding: 10px;
}
</style>
