<template>
  <div class="document-list-container">
    <div class="action-buttons">
      <router-link :to="{ name: 'documentAdd' }">
        <button class="add-document-button">Upload New Document</button>
      </router-link>
      <router-link :to="{ name: 'documentVerify' }">
        <button class="verify-document-button">Verify Document</button>
      </router-link>
    </div>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <table v-if="documents.length" class="document-table">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="document in documents" :key="document.id">
          <td>{{ document.id }}</td>
          <td>{{ document.name }}</td>
          <td>{{ document.documentTypeId ? document.documentTypeId.name : 'Unknown' }}</td>
          <td>
            <button @click="viewDocument(document.id)">View</button>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="no-documents">No documents available.</div>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, DEFAULT_LIST_LIMIT, EGettersType, EModificationType, ESortOrderType, EDocumentSortType } from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
    };
  },
  created: function () {
    this.fetchDocuments();
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_ORGANIZATION_DOCUMENTS
    ])
  },
  methods: {
    fetchDocuments() {
      this.isLoading = true;

      this.$store.dispatch(EModificationType.FETCH_ORGANIZATION_DOCUMENTS, {
        sortOrder: ESortOrderType.DESC,
        sortBy: EDocumentSortType.DATE,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage
      }).then((count) => {
        this.totalDocumentCount = count;
        this.isLoading = false;
        if (this.documents.length === 0) {
          this.currentPage = 1;
        }
      }).catch(e => {
        this.isLoading = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    viewDocument(documentId: string) {
      this.$router.push({ name: 'documentView', params: { id: documentId } });
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchDocuments();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchDocuments();
      }
    }
  }
});
</script>

<style scoped>
.document-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.add-document-button, .verify-document-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-document-button {
  background-color: #2dce89;
  color: #fff;
}

.add-document-button:hover {
  background-color: #1db674;
}

.verify-document-button {
  background-color: #007bff;
  color: white;
}

.verify-document-button:hover {
  background-color: #0056b3;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.filter-item label {
  margin-right: 10px;
}

select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.document-table {
  width: 100%;
  border-collapse: collapse;
}

.document-table th,
.document-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.document-table th {
  background-color: #f4f4f4;
}

.loading {
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}

.no-documents {
  margin: 100px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
