 // Start of Selection
<template>
  <div>
    <h1>Upload New Document</h1>
    <form @submit.prevent="handleSubmit" class="document-form">
      <div class="form-group">
        <label for="name">Document Name:</label>
        <input 
          class="name-input"
          type="text" 
          id="name" 
          v-model="name" 
          required 
        />
      </div>

      <div class="form-group">
        <label for="documentTypeId">Document Type:</label>
        <select id="documentTypeId" v-model="documentTypeId" required class="document-type-select">
          <option value="" disabled>Select document type</option>
          <option v-for="type in documentTypes" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="documentFile">Upload PDF Document:</label>
        <input 
          type="file" 
          id="documentFile" 
          accept="application/pdf" 
          @change="handleFileChange" 
          required 
          class="upload-file-input"
        />
      </div>

      <button 
        type="submit" 
        class="submit-button" 
        :disabled="!isFormValid || isUploading"
      >
        {{ isUploading ? 'Uploading...' : 'Upload Document' }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, DEFAULT_LIST_LIMIT, EDocumentTypeSortType, EGettersType, EModificationType, ESortOrderType } from '../globals';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

interface DocumentFormData {
  name: string;
  documentTypeId: string;
  documentFile: File | null;
  isALetter: boolean;
}

export default Vue.extend({
  data(): DocumentFormData & { isUploading: boolean } {
    return {
      name: '',
      documentTypeId: '',
      documentFile: null,
      isALetter: false,
      isUploading: false
    };
  },
  created: async function() {
    try {
      await this.fetchDocumentTypes();
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USER,
      EGettersType.GET_DOCUMENT_TYPES,
    ]),
    isFormValid(): boolean {
      return this.name.length > 0 && this.documentTypeId.length > 0 && this.documentFile !== null;
    },
  },
  methods: {
    handleFileChange: function (event: Event) {
      const files = (event.target as HTMLInputElement).files;
      if (files && files[0].type === 'application/pdf') {
        this.documentFile = files[0]; // Store selected PDF file
      } else {
        this.documentFile = null; // Reset if not a valid PDF
      }
    },
    handleSubmit: async function () {
      try {
        if (!this.documentFile) {
          throw new Error("Please upload a valid PDF document.");
        }

        this.isUploading = true;
        // Upload file to Firebase
        const fileUrl = await this.uploadFileToFirebase(this.documentFile);

        // Dispatch the create document action with the form data and the Firebase URL
        await this.$store.dispatch(EModificationType.CREATE_DOCUMENT, { 
          name: this.name, 
          documentTypeId: this.documentTypeId, 
          isALetter: this.isALetter, 
          documentUrl: fileUrl 
        });

        // Navigate back to the document list or some confirmation page
        this.$router.push({ name: 'documents' });
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e.message);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      } finally {
        this.isUploading = false;
      }
    },
    uploadFileToFirebase: async function (file: File): Promise<string> {
      try {
        const storageRef = ref(storage, `documents/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);

        // Get the file's URL
        const url = await getDownloadURL(snapshot.ref);
        return url;
      } catch (error) {
        console.log('File upload error:', error);
        throw new Error('Failed to upload file to Firebase.');
      }
    },
    fetchDocumentTypes: async function () {
      try {
        await this.$store.dispatch(EModificationType.FETCH_ORGANIZATION_DOCUMENT_TYPES, {
          sortOrder: ESortOrderType.ASC,
          sortBy: EDocumentTypeSortType.DATE,
          limit: DEFAULT_LIST_LIMIT,
          skip: 0,
          isALetterhead: true
        });
        // You can add any additional logic here if needed after the fetch
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  },
});
</script>

<style scoped>
.document-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  /* width: 100%; */
  padding: 10px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #1db674;
}

.name-input {
  width: 95%;
}

.document-type-select {
  width: 100%;
}

.upload-file-input {
  width: 95%;
}
</style>
