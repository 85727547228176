<template>
  <div>
    <h1>Add New Employee</h1>
    <form @submit.prevent="handleSubmit" class="user-form">
      <div class="form-group">
        <label for="firstName">First Name:</label>
        <input type="text" id="firstName" v-model="firstName" required />
      </div>
      <div class="form-group">
        <label for="lastName">Last Name:</label>
        <input type="text" id="lastName" v-model="lastName" required />
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="phone">Phone:</label>
        <input type="tel" id="phone" v-model="phone" required />
      </div>
      <button type="submit" class="submit-button">Add User</button>
    </form>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EGettersType, EModificationType } from '../globals';
import Vue from 'vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default Vue.extend({
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };
  },
  computed: {
    ...mapGetters([EGettersType.GET_USER]),
  },
  methods: {
    formatPhoneNumber(phone: string) {
      const phoneNumber = parsePhoneNumberFromString(phone, 'BW'); // 'BW' is for Botswana
      return phoneNumber ? phoneNumber.formatInternational() : phone;
    },
    handleSubmit: async function () {
      try {
        const formattedPhone = this.formatPhoneNumber(this.phone);
        await this.$store.dispatch(EModificationType.CREATE_ORGANIZATION_EMPLOYEE, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: formattedPhone,
          locale: this.user.locale,
          tz: this.user.tz,
          country: this.user.country,
        });
        this.$router.push({ name: 'users' }); // Navigate back to users list
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
  },
});
</script>

<style scoped>
.user-form {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust as needed */
  margin: auto; /* Center the form horizontally */
}

.form-group {
  margin-bottom: 1rem; /* Adjust spacing between form groups */
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #1db674;
}
</style>