<template>
  <div>
    <h1>Direct Sales Agents</h1>
    <DirectSalesAgentList />
  </div>
</template>

<script>
import DirectSalesAgentList from '../components/DirectSalesAgentList.vue';

export default {
  name: 'DirectSalesAgentsView',
  components: {
    DirectSalesAgentList
  },
};
</script>