<template>
  <div class="agent-view-container" v-if="agent">
    <h2>Direct Sales Agent Details</h2>
    <div class="agent-details">
      <img v-if="agent.photoUrl" :src="agent.photoUrl" alt="Agent Photo" class="agent-photo">
      <p><strong>First Name:</strong> {{ agent.firstName }}</p>
      <p><strong>Last Name:</strong> {{ agent.lastName }}</p>
      <p><strong>Email:</strong> {{ agent.email }}</p>
      <p><strong>Phone:</strong> {{ agent.phone }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';

export default Vue.extend({
  async created() {
    try {
      await this.fetchAgent();
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  data() {
    return {
      agent: null as any,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  methods: {
    async fetchAgent() {
      try {
        this.agent = await this.$store.dispatch(EModificationType.FETCH_USER_BY_ID, {userId: this.id});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  }
});
</script>

<style scoped>
.agent-view-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.agent-details {
  padding: 10px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agent-photo {
  max-width: 200px;
  max-height: 200px;
  margin-top: 10px;
  border-radius: 50%;
}

.back-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-btn:hover {
  background-color: #0056b3;
}
</style>
