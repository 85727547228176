<template>
  <div>
    <h1>Document Types</h1>
    <DocumentTypeList />
  </div>
</template>

<script>
import DocumentTypeList from '../components/DocumentTypeList.vue';

export default {
  name: 'DocumentTypesView',
  components: {
    DocumentTypeList
  },
};
</script>