<template>
  <div>
    <h1>Add New Document Type</h1>
    <form @submit.prevent="handleSubmit" class="document-form">
      <div class="form-group">
        <label for="name">Document Type Name:</label>
        <input type="text" id="name" v-model="name" required />
      </div>
      <div class="form-group flex-align">
        <label for="isALetterhead">Is the document type a letterhead?:</label>
        <input 
          type="checkbox" 
          id="isALetterhead" 
          v-model="isALetterhead" 
        />
      </div>
      <div class="form-group">
        <label for="qrCodePlacementX">CreditMate Verify QR Code Placement X (px from right edge):</label>
        <small>Starts from the bottom right corner, moving left</small>
        <input type="number" id="qrCodePlacementX" v-model="qrCodePlacementX" required />
      </div>
      <div class="form-group">
        <label for="qrCodePlacementY">CreditMate Verify QR Code Placement Y (px from bottom edge):</label>
        <small>Starts from the bottom right corner, moving up</small>
        <input type="number" id="qrCodePlacementY" v-model="qrCodePlacementY" required />
      </div>
      <button type="submit" class="submit-button">Create Document Type</button>
    </form>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EGettersType, EModificationType } from '../globals';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      name: '',
      isALetterhead: false,
      qrCodePlacementX: 0,
      qrCodePlacementY: 0
    };
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USER
    ])
  },
  methods: {
    handleSubmit: async function () {
      try {
        await this.$store.dispatch(EModificationType.CREATE_DOCUMENT_TYPE, { 
          name: this.name, 
          qrCodePlacementX: this.qrCodePlacementX, 
          qrCodePlacementY: this.qrCodePlacementY,
          isALetterhead: this.isALetterhead
        });
        this.$router.push({ name: 'documentTypes' }); // Navigate back to document types list
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  },
});
</script>

<style scoped>
.document-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #1db674;
}

/* Flex alignment for label and checkbox */
.flex-align {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
