import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EGettersType, EModificationType, NETWORK_ERROR_MESSAGE, SERVER_UNREACHABLE, SERVICE_URLS, UNEXPECTED_ERROR_PREFIX } from '../globals';
import Vue from 'vue';
import Vuex from 'vuex';
import { getAccessToken, logUserOut, setAccessToken, setRefreshToken, setUserRole } from '../util';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isNetworkErrorOrTimeout: false,
    isAuthenticated: false,
    fcmToken: "",
    user: {
      userType: "",
      email: "",
      firstName: "",
      lastName: "",
      name: "",
      phone: "",
      tz: "",
      locale: "",
      createdDate: "",
      country: "",
      updatedDate: "",
      id: ""
    },
    employees: [],
    documents: [],
    documentTypes: [],
    directSalesAgents: []
  },
  getters: {
    [EGettersType.GET_USER]: function (state): any {
      return state.user;
    },
    [EGettersType.GET_IS_AUTHENTICATED]: function (state): boolean {
      return state.isAuthenticated;
    },
    // [EGettersType.GET_FCM_TOKEN]: function (state): string {
    //   return state.fcmToken;
    // },
    [EGettersType.GET_EMPLOYEES]: function (state): any {
      return state.employees;
    },
    [EGettersType.GET_ORGANIZATION_DOCUMENTS]: function (state: any): any {
      return state.documents;
    },
    [EGettersType.GET_DOCUMENT_TYPES]: function (state: any): any {
      return state.documentTypes;
    },
    [EGettersType.GET_DIRECT_SALES_AGENTS]: function (state: any): any {
      return state.directSalesAgents;
    },
    [EGettersType.GET_USER_ROLE]: function (state: any): any {
      if (state.user) {
        return state.user.userType;
      } else {
        return '';
      }
    },
  },
  mutations: {
    [EModificationType.SET_USER]: (state, value) => {
      state.user = value;
    },
    [EModificationType.SET_EMPLOYEES]: (state, value) => {
      state.employees = value;
    },
    [EModificationType.SET_IS_AUTHENTICATED]: (state, value) => {
      state.isAuthenticated = value;
    },
    [EModificationType.SET_DIRECT_SALES_AGENTS]: (state, value) => {
      state.directSalesAgents = value;
    },
    [EModificationType.SET_DOCUMENT_TYPES]: (state, value) => {
      state.documentTypes = value;
    },
    // [EModificationType.SET_FCM_TOKEN]: (state, value) => {
    //   state.fcmToken = value;
    // }
    [EModificationType.SET_ORGANIZATION_DOCUMENTS]: (state, value) => {
      state.documents = value;
    },
  },
  actions: {
    [EModificationType.LOG_IN_WITH_LOCAL_AUTH]: async (context, { password, email }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        // Generate token id and secret
        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAuthURI}/login`, {
          method: "POST",
          body: JSON.stringify({
            email,
            password
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status === 200) {
          const { accessToken, refreshToken } = await queryResponse.json();
          // Store auth token in persistent store
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
          context.commit(EModificationType.SET_IS_AUTHENTICATED, true);
          return;
        } else {
          const queryResponseData = await queryResponse.json();
          if (queryResponseData.error) {
            throw new Error(queryResponseData.error); // TODO: Make 'Invalid token or secret more user friendly'
          } if (queryResponseData.message) {
            throw new Error(queryResponseData.message); // TODO: Make 'Invalid token or secret more user friendly'
          } else {
            // TODO: How to alert engineers of this?
            throw new Error('Unknown error');
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.SEND_PASSWORD_RESET_LINK]: async (context, { email }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        // Generate token id and secret
        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAuthURI}/forgot-password`, {
          method: "POST",
          body: JSON.stringify({
            email
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status === 200) {
          return;
        } else {
          const queryResponseData = await queryResponse.json();
          if (queryResponseData.error) {
            throw new Error(queryResponseData.error);
          } if (queryResponseData.message) {
            throw new Error(queryResponseData.message); // TODO: Make 'Invalid token or secret more user friendly'
          } else {
            // TODO: How to alert engineers of this?
            throw new Error('Unknown error');
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.RESET_PASSWORD]: async (context, { newPassword, token }) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }

        // Generate token id and secret
        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAuthURI}/reset-password`, {
          method: "POST",
          body: JSON.stringify({
            newPassword,
            token
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status === 200) {
          return;
        } else {
          const queryResponseData = await queryResponse.json();
          if (queryResponseData.error) {
            throw new Error(queryResponseData.error);
          } if (queryResponseData.message) {
            throw new Error(queryResponseData.message); // TODO: Make 'Invalid token or secret more user friendly'
          } else {
            // TODO: How to alert engineers of this?
            throw new Error('Unknown error');
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_USER_BY_ID]: async (context, { userId }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/users/getUserById?id=${userId}`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_USER_BY_ID, { userId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const { user } = await queryResponse.json();
        return user;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.LOG_OUT]: async (context) => {
      try {

        // if (context.state.isNetworkErrorOrTimeout) {
        //   context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        // }
        //
        // const accessToken = getAccessToken();
        //
        // // Fetch account
        // const queryResponse = await fetch(`${SERVICE_URLS.creditmateAuthURI}/logout`, {
        //   method: "POST",
        //   headers: {
        //     "Authorization": `Bearer ${accessToken}`,
        //     "Content-Type": "application/json"
        //   }
        // });
        //
        // // Check status
        // if (queryResponse.status !== 200) {
        //   if (queryResponse.ok === false) {
        //     if (queryResponse.status === 503) {
        //       await context.dispatch(EModificationType.LOG_OUT);
        //     } else {
        //       const bodyResponse = await queryResponse.json();
        //       throw new Error(bodyResponse.error);
        //     }
        //   } else {
        //     throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        //   }
        // }

        logUserOut();
        return true;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_OWN_ACCOUNT]: async (context) => {
      try {

        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();
    
        // Fetch account
        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/users/getOwnAccount`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_OWN_ACCOUNT);
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const accountData = await queryResponse.json();
        const account = accountData.user;
        setUserRole(account.userType);
        context.commit(EModificationType.SET_USER, account);
        return account;
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_ORGANIZATION_DOCUMENTS]: async (context, { skip, sortOrder, sortBy, limit }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.creditmateAppURI}/documents/getOwnOrganizationDocuments?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        // Fetch users
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_ORGANIZATION_DOCUMENTS, { skip, sortOrder, sortBy, limit });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, documents } = result;
        context.commit(EModificationType.SET_ORGANIZATION_DOCUMENTS, documents);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_ORGANIZATION_DOCUMENT_TYPES]: async (context, { skip, sortOrder, sortBy, limit, isALetterhead }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.creditmateAppURI}/documentTypes/getOwnOrganizationDocumentTypes?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`

        if (typeof isALetterhead === 'boolean') {
          fetchURI = `${fetchURI}&isALetterhead=${isALetterhead}`;
        }

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        // Fetch users
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_ORGANIZATION_DOCUMENT_TYPES, { skip, sortOrder, sortBy, limit, isALetterhead });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, documentTypes } = result;
        context.commit(EModificationType.SET_DOCUMENT_TYPES, documentTypes);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_EMPLOYEES]: async (context, { skip, sortOrder, sortBy, limit, userType }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.creditmateAppURI}/users/getOwnOrganizationEmployees?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        if (userType) {
          fetchURI = `${fetchURI}&userType=${userType}`;
        }

        // Fetch users
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_EMPLOYEES, { skip, sortOrder, sortBy, limit, userType });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, users } = result;
        context.commit(EModificationType.SET_EMPLOYEES, users);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_DIRECT_SALES_AGENTS]: async (context, { skip, sortOrder, sortBy, limit }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        let fetchURI = `${SERVICE_URLS.creditmateAppURI}/users/getDirectSalesAgents?sortOrder=${sortOrder}&sortBy=${sortBy}&limit=${limit}`

        if (typeof skip === 'number') {
          fetchURI = `${fetchURI}&skip=${skip}`;
        }

        // Fetch users
        const queryResponse = await fetch(fetchURI, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_DIRECT_SALES_AGENTS, { skip, sortOrder, sortBy, limit });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const {result} = await queryResponse.json();
        const { count, users } = result;
        context.commit(EModificationType.SET_DIRECT_SALES_AGENTS, users);
        return count;
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_DOCUMENT_BY_ID]: async (context, { documentId }) => {
      try {
        
        if (context.state.isNetworkErrorOrTimeout) {
          context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, false);
        }
    
        const accessToken = getAccessToken();

        // Fetch users
        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/documents/getOwnOrganizationDocumentById?id=${documentId}`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (!queryResponse.ok) {
            if (queryResponse.status === 503) {
              await context.dispatch(EModificationType.FETCH_DOCUMENT_BY_ID, { documentId });
            } else {
              const bodyResponse = await queryResponse.json();
              throw new Error(bodyResponse.error);
            }
          } else {
            throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }
    
        const { document } = await queryResponse.json();

        return document
    
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_DOCUMENT_TYPE]: async (context, { name, qrCodePlacementX, qrCodePlacementY, isALetterhead }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/documentTypes/createDocumentType`, {
          method: "POST",
          body: JSON.stringify({
            name,
            qrCodePlacementX,
            qrCodePlacementY,
            isALetterhead
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_DOCUMENT_TYPE, { name, qrCodePlacementX, qrCodePlacementY, isALetterhead });
          } else {
            const bodyResponse = await queryResponse.json();
            if (queryResponse.status === 400) {
              throw new Error(bodyResponse.details[0].message);
            } else {
              throw new Error(bodyResponse.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        const { documentType } = await queryResponse.json();    
        return documentType;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);  
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_DOCUMENT]: async (context, { name, documentTypeId, isALetter, documentUrl }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/documents/createDocument`, {
          method: "POST",
          body: JSON.stringify({
            name,
            documentTypeId,
            isALetter,
            documentUrl
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_DOCUMENT, { name, documentTypeId, isALetter, documentUrl });
          } else {
            const bodyResponse = await queryResponse.json();
            if (queryResponse.status === 400) {
              throw new Error(bodyResponse.details[0].message);
            } else {
              throw new Error(bodyResponse.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        const { document } = await queryResponse.json();
        return document;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.VERIFY_DOCUMENT]: async (context, { formData }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/documents/verifyDocument`, {
          method: "POST",
          body: formData,
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.VERIFY_DOCUMENT, { document });
          } else {
            const bodyResponse = await queryResponse.json();
            throw new Error(bodyResponse.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const { report } = await queryResponse.json();
        return report;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_ORGANIZATION]: async (context, { name, kycDocumentsURL, email, identificationNumber, phone, locale, tz }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/organizations/createOrganization`, {
          method: "POST",
          body: JSON.stringify({
            name,
            kycDocumentsURL,
            email,
            identificationNumber,
            phone,
            locale,
            tz
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_ORGANIZATION, { name, kycDocumentsURL, email, identificationNumber, phone, locale, tz });
          } else {
            const bodyResponse = await queryResponse.json();
            if (queryResponse.status === 400) {
              throw new Error(bodyResponse.details[0].message);
            } else {
              throw new Error(bodyResponse.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        const { user } = await queryResponse.json();
        return user;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_ORGANIZATION_EMPLOYEE]: async (context, { firstName, lastName, email, phone, locale, tz, country }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/users/createOrganizationEmployee`, {
          method: "POST",
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            locale,
            tz,
            country
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_ORGANIZATION_EMPLOYEE, { firstName, lastName, email, phone, locale, tz });
          } else {
            const bodyResponse = await queryResponse.json();
            if (queryResponse.status === 400) {
              throw new Error(bodyResponse.details[0].message);
            } else {
              throw new Error(bodyResponse.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        const { user } = await queryResponse.json();
        return user;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT]: async (context, { firebaseDeviceToken }) => {
      try {

        const accessToken = getAccessToken();

        // Update firebase device token
        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/users/setFirebaseDeviceTokenOnOwnAccount`, {
          method: "PUT",
          body: JSON.stringify({
            firebaseDeviceToken
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
    
        // Check status
        if (queryResponse.status !== 200) {
          if (queryResponse.status !== 304) {
            if (queryResponse.ok === false) {
              if (queryResponse.status === 503) {
                await context.dispatch(EModificationType.SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT, { firebaseDeviceToken });
              } else {
                const bodyResponse = await queryResponse.json();
                throw new Error(bodyResponse.error);
              }
            } else {
              throw new Error(DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }
      } catch (error) {
        // TODO: Handle error
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    },
    [EModificationType.FETCH_DOCUMENT_TYPE_BY_ID]: async (context, { id }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/documentTypes/getOwnOrganizationDocumentTypeById?id=${id}`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });

        if (queryResponse.status !== 200) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.FETCH_DOCUMENT_TYPE_BY_ID, { id });
          } else {
            const bodyResponse = await queryResponse.json();
            throw new Error(bodyResponse.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          }
        }

        const { documentType } = await queryResponse.json();
        return documentType;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else {
          throw error;
        }
      }
    },
    [EModificationType.CREATE_DIRECT_SALES_AGENT]: async (context, { firstName, lastName, email, phone, locale, tz, photoUrl, country }) => {
      try {
        const accessToken = getAccessToken();

        const queryResponse = await fetch(`${SERVICE_URLS.creditmateAppURI}/users/createDirectSalesAgent`, {
          method: "POST",
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            locale,
            tz,
            photoUrl,
            country
          }),
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });

        // Check status
        if (queryResponse.status !== 201) {
          if (queryResponse.status === 503) {
            await context.dispatch(EModificationType.CREATE_DIRECT_SALES_AGENT, { firstName, lastName, email, phone, locale, tz, photoUrl });
          } else {
            const bodyResponse = await queryResponse.json();
            if (queryResponse.status === 400) {
              throw new Error(bodyResponse.details[0].message);
            } else {
              throw new Error(bodyResponse.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
            }
          }
        }

        const { user } = await queryResponse.json();
        return user;
      } catch (error) {
        if (error instanceof TypeError && error.message === NETWORK_ERROR_MESSAGE) {
          if (!context.state.isNetworkErrorOrTimeout) {
            context.commit(EModificationType.SET_IS_NETWORK_ERROR_OR_TIMEOUT, true);
          }
        } else if (error instanceof SyntaxError && error.message.includes(UNEXPECTED_ERROR_PREFIX)) {
          throw new Error(SERVER_UNREACHABLE);
        } else {
          throw error;
        }
      }
    }
  }
})
