// Import the necessary Firebase modules
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyCywil9XpyIDjyLBe6qiNuunfRdE3HMrXw",
  authDomain: "creditmate-a9015.firebaseapp.com",
  projectId: "creditmate-a9015",
  storageBucket: "creditmate-a9015.appspot.com",
  messagingSenderId: "808471862737",
  appId: "1:808471862737:web:72f0c4ebf16271bc8254f0",
  measurementId: "G-4ZH0VT8PZZ"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

// Export the Firebase storage instance
export { storage };
