import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import DashboardView from '../views/DashboardView.vue';
import EmployeesView from '../views/EmployeesView.vue';
import LoginView from '../views/LoginView.vue';
import AddEmployeeView from '../views/AddEmployeeView.vue';
import AddDocumentView from '../views/AddDocumentView.vue';
import AddDocumentTypeView from '../views/AddDocumentTypeView.vue';
import DocumentTypeView from '../views/DocumentTypeView.vue';
import DocumentTypesView from '../views/DocumentTypesView.vue';
import EmployeeView from '../views/EmployeeView.vue';
import DocumentView from '../views/DocumentView.vue';
import DocumentsView from '../views/DocumentsView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';
import VerifyDocumentView from '../views/VerifyDocumentView.vue';
import DirectSalesAgentView from '../views/DirectSalesAgentView.vue';
import DirectSalesAgentsView from '../views/DirectSalesAgentsView.vue';
import AddDirectSalesAgentView from '../views/AddDirectSalesAgentView.vue';
import store from '../store';
import {EGettersType, EUserType} from '../globals';
import { getUserRole } from '../util';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.CONSUMER, EUserType.COOPERATIVE, EUserType.EMPLOYEE, EUserType.ORGANIZATION] },
    children: [

      {
        path: 'directSalesAgents/add',
        name: 'directSalesAgentAdd',
        component: AddDirectSalesAgentView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION, EUserType.EMPLOYEE] }
      },
      {
        path: 'directSalesAgents/:id',
        name: 'directSalesAgentView',
        component: DirectSalesAgentView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION, EUserType.EMPLOYEE] }
      },
      {
        path: 'directSalesAgents',
        name: 'directSalesAgents',
        component: DirectSalesAgentsView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION, EUserType.EMPLOYEE] }
      },
      {
        path: 'employees/add',
        name: 'employeeAdd',
        component: AddEmployeeView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION] }
      },
      {
        path: 'employees/:id',
        name: 'employeeView',
        component: EmployeeView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION] }
      },
      {
        path: 'employees',
        name: 'employees',
        component: EmployeesView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION] }
      },
      {
        path: 'documents/add',
        name: 'documentAdd',
        component: AddDocumentView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION,  EUserType.EMPLOYEE] }
      },
      {
        path: 'documents/verify',
        name: 'documentVerify',
        component: VerifyDocumentView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION, EUserType.EMPLOYEE] }
      },
      {
        path: 'documents/:id',
        name: 'documentView',
        component: DocumentView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION, EUserType.EMPLOYEE] }
      },
      {
        path: 'documents',
        name: 'documents',
        component: DocumentsView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION, EUserType.EMPLOYEE] }
      },
      {
        path: 'documentTypes/add',
        name: 'documentTypeAdd',
        component: AddDocumentTypeView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION] }
      },
      {
        path: 'documentTypes/:id',
        name: 'documentTypeView',
        component: DocumentTypeView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION] }
      },
      {
        path: 'documentTypes',
        name: 'documentTypes',
        component: DocumentTypesView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ORGANIZATION] }
      }
    ]
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPasswordView
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
    props: route => ({ token: route.query.token })
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters[EGettersType.GET_IS_AUTHENTICATED];
  const userRole = getUserRole();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const allowedRoles = to.matched.some(record => record.meta.allowedRoles);

  // Redirect unauthenticated users to the login page
  if (requiresAuth && !isAuthenticated) {
    console.log("redirecting to login");
    return next('/login');
  }

  // Check for allowed roles
  if (requiresAuth && allowedRoles && to.meta!.allowedRoles && !to.meta!.allowedRoles.includes(userRole)) {
    return next('/documents'); // Redirect to an accessible page
  }

  // Handle root redirection
  if (to.path === '/' && isAuthenticated) {
    return next('/documents'); // Redirect to default page after login
  }

  next();
});

export default router;
