<template>
  <div>
    <h1>Employees</h1>
    <EmployeeList />
  </div>
</template>

<script>
import EmployeeList from '../components/EmployeeList.vue';

export default {
  name: 'EmployeesView',
  components: {
    EmployeeList
  },
};
</script>